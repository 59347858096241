import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ICompany, IProduct } from '@tfi-workspace-web/tfi-shared-utils';
import { CatalogViewService } from '../../services/catalog/view/catalog-view.service';
import { catchError, EMPTY, Subject, take, takeUntil } from 'rxjs';
import { GCComponentsModule } from '@gc-tech/components';
import { ProductFilters } from '@tfi-workspace-web/tfi-feat-product-management';

@Component({
  selector: 'e-tfi-ecom-product-detail',
  standalone: true,
  imports: [CommonModule, GCComponentsModule],
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  product!: IProduct;
  destroy$: Subject<boolean> = new Subject<boolean>();
  company!: ICompany;
  filters: ProductFilters;
  isLoading = true;
  domain = '';
  constructor(
    private route: ActivatedRoute,
    private catalogViewService: CatalogViewService
  ) {
    this.filters = new ProductFilters();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.filters.productName = '';
    this.saveFiltersInStorage();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      let prodName = params.get('productName') ?? '';
      prodName = prodName.split('__SP__').join('-');
      prodName = prodName.split('-').join(' ');
      this.filters.productName = prodName;
      this.saveFiltersInStorage();
      this.fetchProduct(prodName);
    });
    this.catalogViewService.company$
      .pipe(takeUntil(this.destroy$))
      .subscribe((company) => {
        this.company = company;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get mainImg() {
    return this.product?.mainImageUrl ?? '';
  }

  get images() {
    return this.product?.images ?? [];
  }

  fetchProduct(prodName: string): void {
    this.catalogViewService
      .getProductDetail(prodName)
      .pipe(
        take(1),
        catchError(() => {
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.filters.categoryName = res.data.product.categoryName;
          this.catalogViewService.updateCat(
            this.filters.categoryName as string
          );
          this.saveFiltersInStorage();
          this.product = res.data?.product;
        }
        this.isLoading = false;
      });
  }

  openWhatsapp() {
    const baseURL = 'https://api.whatsapp.com/send';
    const phoneNumber = this.company.socialMedia?.whatsapp;
    const message = 'Estoy interesado en este producto: ';
    const urlToEncode = window.location.href;
    const encodedURL = encodeURIComponent(urlToEncode);
    const fullWhatsAppURL = `${baseURL}?phone=${phoneNumber}&text=${message}${encodedURL}`;
    window.open(fullWhatsAppURL, '_blank');
  }

  saveFiltersInStorage() {
    localStorage.setItem('catalogFilters', JSON.stringify(this.filters));
  }
}
