import { CompanyIndustryEnum } from '../enums/company.enum';
import { CurrencyEnum } from '../enums/currency.enum';
import { IBaseAddress } from './base-address.interface';
import { IBaseEntity } from './base-entity.interface';
import { ITaxDetail } from './tax.interface';

export interface ICompany extends IBaseEntity {
  displayName: string;
  legalName?: string;
  email: string;
  phoneNumber: string;
  location: IBaseAddress;
  industry: CompanyIndustryEnum;
  taxINumber?: string;
  taxDetail?: ITaxDetail[];
  defaultCurrency: CurrencyEnum;
  authorizedUsers: string[];
  businessParams?: IBusinessParams;
  mainLogo?: string;
  slogan?: string;
  socialMedia?: {
    whatsapp?: string;
    instagram?: string;
    facebook?: string;
    phoneNumber?: string;
    tiktok?: string;
    youtube?: string;
    twitter?: string;
  };
  paymentGateways?: {
    wompi?: IWompi;
  };
}

export interface IBusinessParams {
  enableKDS: {
    value: boolean;
    description?: string;
  };
  enableZebraPrinter: {
    value: boolean;
    description?: string;
  };
  enableHideStockZero: {
    value: boolean;
    description?: string;
  };
  enableTipPercentage: {
    isEnabled: boolean;
    value: number;
    description?: string;
  };
}

export const defaultBusinessParams = {
  enableKDS: {
    value: false,
  },
  enableZebraPrinter: {
    value: false,
  },
  enableHideStockZero: {
    value: false,
  },
  enableTipPercentage: {
    isEnabled: false,
    value: 0,
  },
};

export type UpdatedCompany = Partial<ICompany>;

export interface ICompaniesResponse {
  status: string;
  results: number;
  data: {
    companies: ICompany[];
  };
}

export interface ICompanyResponse {
  status: string;
  data: {
    company: ICompany;
  };
}

export interface IWompi {
  clientId: string;
  secretKey: string;
  productName: string;
  emailNotification: string;
  notifyClient: boolean;
  commission: number;
  providerKey: PaymentProviderEnum;
}

export enum PaymentProviderEnum {
  WOMPI = 'WOMPI',
}
