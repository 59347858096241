export enum discountTypeEnum {
  VALUE = 'VALUE',
  PERCENTAGE = 'PERCENTAGE',
}
export enum PaymentTypeEnum {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  CHECK = 'CHECK',
  WIRE = 'WIRE',
  BTC = 'BTC',
}

export enum SelectPrintOptionEnum {
  KITCHEN_ORDER = 'KITCHEN_ORDER',
  TICKET = 'TICKET',
}
