import { createSelector, createFeatureSelector } from '@ngrx/store';
import { catalogState } from '../interfaces/catalog-store.interface';

export const selectAppState =
  createFeatureSelector<catalogState>('catalogStore');
export const selectLoading = createSelector(
  selectAppState,
  (state) => state.isLoading
);
export const selectCurrentRoute = createSelector(
  selectAppState,
  (state) => state.currentRoute
);
