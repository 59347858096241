<header class="header-container">
  <div class="header-wrapper">
    <div class="logo-container">
      <img
        *ngIf="logoImage; else skeleton"
        class="logo-container__img"
        src="{{ !!logoImage ? logoImage : '/assets/images/tefi-logo.svg' }}"
        alt="company-logo"
      />
    </div>
    <!--ul class="desktop-menu-container">
      <li
        *ngFor="let menuOption of menuItems"
        class="menu-item"
        (click)="showMenu(menuOption)"
        [routerLink]="menuOption.path"
      >
        <ng-container>
          <a
            class="menu-item__link"
            [routerLink]="menuOption.path"
            routerLinkActive="active-link"
          >
            <div class="menu-label-container">
              <gc-icon
                icon="chevron-down"
                [dimension]="16"
                *ngIf="menuOption.children.length > 0"
              ></gc-icon>
              <span class="menu-label-container__label">{{
                menuOption.label
              }}</span>
            </div>
          </a>
          <ul
            class="inner-options"
            *ngIf="menuOption.isSelected && menuOption.children.length > 0"
            [@menuAnimation]
          >
            <li
              class="inner-options__item"
              *ngFor="let item of menuOption.children"
            >
              <a [routerLink]="item.path">{{ item.label }}</a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul-->
    <div></div>
    <gc-icon
      class="header-wrapper__mobile-menu-button"
      icon="menu"
      (click)="onMobileMenuClicked()"
      color="grey-500"
      [dimension]="22"
    ></gc-icon>
  </div>

  <ng-template #skeleton>
    <p-skeleton width="130px" height="80px" class="logo-skeleton" />
    <p-skeleton width="130px" height="50px" class="logo-skeleton--mobile" />
  </ng-template>
</header>
