import { createAction, props } from '@ngrx/store';

export const setLoading = createAction(
  '[App] Set Loading',
  props<{ isLoading: boolean }>()
);
export const setRoute = createAction(
  '[App] Set Route',
  props<{ currentRoute: string }>()
);
