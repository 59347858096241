import { createReducer, on } from '@ngrx/store';
import { catalogState } from '../interfaces/catalog-store.interface';
import * as catalogActions from '../actions/catalog-store.actions';

export const initialState: catalogState = {
  isLoading: false,
  currentRoute: '',
};

export const catalogReducer = createReducer(
  initialState,
  on(catalogActions.setLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),
  on(catalogActions.setRoute, (state, { currentRoute }) => ({
    ...state,
    currentRoute,
  }))
);
